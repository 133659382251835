import { getNavigation, NavigationLink, NavigationDirectoryResponse } from '@revel/revel-api-client';
import axios from 'axios';

import { featureToggleUrl, navigationUrl, applicationName } from '../shared/constants';

import { KeyedLinks } from '@/types/general.types';

export function getLinkByKey(keyedLinks: KeyedLinks, key: string): NavigationLink | null {
	return keyedLinks && key && keyedLinks[key] ? keyedLinks[key] : null;
}

export function getUrlByKey(keyedLinks: KeyedLinks, key: string): string | null {
	const link = getLinkByKey(keyedLinks, key);

	return link ? link.path : null;
}

export function isUrlMatch(url: string): boolean {
	// Duck type the url to see if we're working with a domain-relative path or something else.
	const relativeRegex = /^\/[^/].*$/;
	const comparison = relativeRegex.test(url) ? window.location.pathname : window.location.href;

	return url === comparison;
}

export async function fetchCortexNavigation(): Promise<NavigationDirectoryResponse> {
	return await getNavigation({
		config: { baseUrl: navigationUrl },
		params: { app: applicationName },
		axios,
	});
}

export const validateToken = async() => await axios
	.get(featureToggleUrl)
	.then(response => response.data);
