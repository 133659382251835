import { render, staticRenderFns } from "./link-menu-item.vue?vue&type=template&id=0cdd4f42&"
import script from "./link-menu-item.ts?vue&type=script&lang=ts&"
export * from "./link-menu-item.ts?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./link-menu-item.vue?vue&type=style&index=0&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports