import { CUSTOM_EVENT_NAMES } from '@revel/mf-data-link';
import RevelBuefy from '@revel/revel-buefy';
import Vue, { PropOptions } from 'vue';
import Vuex from 'vuex';

import LinkNavbarItem from '@/components/link-navbar-item/link-navbar-item.vue';
import UserDropdown from '@/components/user-dropdown/user-dropdown.vue';
import { CYPRESS_LOCATORS } from '@/cypress-locators';
import { NAMED_URL_KEYS } from '@/shared/constants';
import { jsonFilter } from '@/shared/filters';
import { getLinkByKey, getUrlByKey } from '@/shared/utils';
import { storeOptions } from '@/store/main.store';

Vue.use(RevelBuefy);
Vue.use(Vuex);
Vue.filter('json', jsonFilter);

export default Vue.extend({
	name: 'Header',
	store: new Vuex.Store(storeOptions),
	components: {
		LinkNavbarItem,
		UserDropdown,
	},
	data() {
		return {
			NAMED_URL_KEYS,
			CYPRESS_LOCATORS,
		};
	},
	computed: {
		loginLink: function() {
			const link = getLinkByKey(this.$store.getters.keyedLinks, NAMED_URL_KEYS.LOGIN);

			if (link) {
				return {
					label: link.label,
					path: `${ link.path }?redirect=${ encodeURIComponent(window.location.href) }`,
				};
			} else {
				return null;
			}
		},
	},
	mounted() {
		this.$store.dispatch('loadNavigationDirectory')
			.catch((error) => {
				console.error(error);
			});
		this.$store.dispatch('loadUser');

		document.addEventListener(CUSTOM_EVENT_NAMES.CORTEX_USER_CHANGE, () => {
			this.$store.dispatch('loadUser');
		});
	},
	methods: {
		getLinkByKey,
		getUrlByKey,
	},
});
