export const NAMED_URL_KEYS = {
	ABOUT: 'about',
	HELP: 'help',
	CHANGE_PASSWORD: 'change-password',
	LOGIN: 'login',
	LOGOUT: 'logout',
	HOME: 'home',
};

const API_KEY_TOKEN = 'enhanced-security-service';

export const CACHE_LIFESPAN = 120;

export const featureToggleUrl = `/api/featureflag/v1.0/lookup/Authorization/${ API_KEY_TOKEN }`;

export const navigationUrl = '/api/navigation/';

export const applicationName = 'cortex';
