import { User } from '@revel/mf-data-link';
import Vue, { PropOptions } from 'vue';

import { CYPRESS_LOCATORS } from '@/cypress-locators';
import { NAMED_URL_KEYS } from '@/shared/constants';
import { getLinkByKey, getUrlByKey } from '@/shared/utils';

export default Vue.extend({
	name: 'UserDropdown',
	props: {
		user: {
			type: Object,
			required: true,
		} as PropOptions<User>,
	},
	data() {
		return {
			NAMED_URL_KEYS,
			CYPRESS_LOCATORS,
		};
	},
	methods: {
		getLinkByKey,
		getUrlByKey,
	},
});
