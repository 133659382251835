import { NavigationLink } from '@revel/revel-api-client';
import Vue, { PropOptions } from 'vue';

import McMenuItem from '@/components/link-menu-item/link-menu-item.vue';
import { isUrlMatch } from '@/shared/utils';

export default Vue.extend({
	name: 'McMenuParent',
	components: {
		McMenuItem,
	},
	props: {
		link: {
			type: Object,
			required: true,
		} as PropOptions<NavigationLink>,
	},
	data() {
		return {
			isActive: false
		};
	},
	computed: {
		// TODO : Will this work for internal route change (ie no pageload)?
		hasUrlMatch: function() {
			return this.link.children.reduce((hasMatch: boolean, child: NavigationLink) => {
				return isUrlMatch(child.path) ? true : hasMatch;
			}, false);
		},
		isVisuallyActive() {
			// @ts-ignore
			return this.isActive || this.hasUrlMatch;
		},
		shouldBeExpanded() {
			const currentUrl = location.pathname.toString(); // relative url
			return currentUrl.indexOf(this.link.path) !== -1
		},
	},
});
