import { getCacheDataOrRequest, User, retrieveUser, STORAGE_KEYS } from '@revel/mf-data-link';
import { NavigationDirectoryResponse, NavigationLinkResponse } from '@revel/revel-api-client';
import { StoreOptions } from 'vuex';

import { CACHE_LIFESPAN } from '@/shared/constants';
import { fetchCortexNavigation } from '@/shared/utils';
import { KeyedLinks, RootState } from '@/types/general.types';

function filterNodes(nodes: NavigationLinkResponse[]): NavigationLinkResponse[] {
	return nodes.filter(node => {
		if (node.children.length > 0) {
			node.children = filterNodes(node.children);
		}

		return node.isVisible;
	});
}

export const storeOptions: StoreOptions<RootState> = {
	state: {
		navigationDirectory: {
			tree: [],
			namedNodes: [],
		},
		user: null,
		promise: null,
	},
	mutations: {
		setNavigationDirectory(state, directory: NavigationDirectoryResponse): void {
			const filteredTree = filterNodes(directory.tree);
			const filteredNamedNodes = filterNodes(directory.namedNodes);
			state.navigationDirectory = { tree: filteredTree, namedNodes: filteredNamedNodes };
		},
		setUser(state, user: User): void {
			state.user = user;
		},
		setPromise(state, promise: Promise<NavigationDirectoryResponse>): void {
			state.promise = promise;
		},
	},
	actions: {
		async loadNavigationDirectory(store): Promise<void> {
			try {
				if (!store.state.promise) {
					const promise = getCacheDataOrRequest({
						request: fetchCortexNavigation,
						key: STORAGE_KEYS.NAV_DIRECTORY,
						lifespan: CACHE_LIFESPAN,
					}).then((response) => {
						store.commit('setNavigationDirectory', response);
					  });

					  store.commit('setPromise', promise);
				}
			} catch (error) {
				console.error(error);
			}
		},

		loadUser(store): Promise<void> {
			return new Promise((resolve) => {
				store.commit('setUser', retrieveUser());
				resolve();
			});
		},
	},
	getters: {
		keyedLinks(state: RootState): KeyedLinks {
			const keyed: KeyedLinks = {};
			if (state.navigationDirectory?.namedNodes) {
				state.navigationDirectory.namedNodes.forEach((link) => {
					keyed[link.label.toLowerCase()] = link;
				});
			}

			return keyed;
		},
	},
};
