export const CYPRESS_LOCATORS = {
	HEADER: {
		ROOT: 'header__root',
		LOGIN_LINK: 'header__login-link',
		USER_DROPDOWN: 'header__user-dropdown',
	},
	SIDENAV: {
		ROOT: 'sidenav__root',
		MENU: 'sidenav__menu',
	},
	USER_DROPDOWN: {
		IMAGE: 'user-dropdown__image',
		GREETING: 'user-dropdown__greeting',
		LOGOUT: 'user-dropdown__logout',
	},
};
