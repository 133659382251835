import { NavigationLink } from '@revel/revel-api-client';
import Vue, { PropOptions } from 'vue';

export default Vue.extend({
	name: 'LinkNavbarItem',
	props: {
		link: {
			type: Object,
			required: true,
		} as PropOptions<NavigationLink>,
	},
});
