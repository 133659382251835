import { NavigationLink } from '@revel/revel-api-client';
import Vue, { PropOptions } from 'vue';

import { isUrlMatch } from '@/shared/utils';

export default Vue.extend({
	name: 'LinkMenuItem',
	props: {
		link: {
			type: Object,
			required: true,
		} as PropOptions<NavigationLink>,
	},
	computed: {
		isActive() {
			return isUrlMatch(this.link.path);
		},
	},
});
