import RevelBuefy from '@revel/revel-buefy';
import Vue from 'vue';
import Vuex from 'vuex';

import LinkMenuItem from '@/components/link-menu-item/link-menu-item.vue';
import LinkMenuParent from '@/components/link-menu-parent/link-menu-parent.vue';
import { CYPRESS_LOCATORS } from '@/cypress-locators';
import { NAMED_URL_KEYS } from '@/shared/constants';
import { jsonFilter } from '@/shared/filters';
import { getLinkByKey, getUrlByKey } from '@/shared/utils';
import { storeOptions } from '@/store/main.store';

Vue.use(Vuex);
Vue.use(RevelBuefy);
Vue.filter('json', jsonFilter);

export default Vue.extend({
	name: 'Sidenav',
	store: new Vuex.Store(storeOptions),
	components: {
		LinkMenuItem,
		LinkMenuParent,
	},
	data() {
		return {
			NAMED_URL_KEYS,
			CYPRESS_LOCATORS,
		};
	},
	mounted() {
		this.$store.dispatch('loadNavigationDirectory')
			.catch((error) => {
				console.error(error);
			});
	},
	methods: {
		getLinkByKey,
		getUrlByKey,
	},
});
